// extracted by mini-css-extract-plugin
export var abbreviations = "menu-module--abbreviations--004bd";
export var adBannerContainer = "menu-module--adBannerContainer--4dcc1";
export var dayMenu = "menu-module--dayMenu--eb50b";
export var disabled = "menu-module--disabled--2e340";
export var isVisible = "menu-module--isVisible--26ed7";
export var now = "menu-module--now--2dc77";
export var nutritions = "menu-module--nutritions--35104";
export var pageTitle = "menu-module--pageTitle--4529e";
export var toggleContent = "menu-module--toggleContent--0e52c";
export var weekList = "menu-module--weekList--f2cee";
export var weekListBottom = "menu-module--weekListBottom--c00fe";
export var weekListTop = "menu-module--weekListTop--4e770";