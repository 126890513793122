import React, { useState, useEffect } from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import * as styles from './menu.module.css'
import infoImg from '../images/info.png'
import favYes from '../images/fav_yes.svg'
import favNo from '../images/fav_no.svg'

export default function Week({ pageContext }) {
  const currentDate = new Date();
  const today = `${currentDate.getDate()}_${currentDate.getMonth() + 1}_`;

  const pageWeekStart = new Date(pageContext.menu.Start);

  let pageWeekEnd = new Date(pageContext.menu.Start);
  pageWeekEnd.setDate(pageWeekEnd.getDate() + 7);
  const isCurrentWeek = (currentDate < pageWeekEnd && currentDate > pageWeekStart);

  // Toggle element visibility
  const toggle = elemId => {
    document.getElementById(elemId).classList.toggle(styles.isVisible);
  };

  const [ownRestaurant, setOwnRestaurant] = useState(false);

  // This runs when the page is loaded.
  useEffect(() => {
    try {
      if (window) {
        const adsbygoogle = window.adsbygoogle || [];
        adsbygoogle.push({});
      }
    } catch (e) {
      console.error(e);
    }

    let cdElem = document.getElementById(today);

    if (cdElem) {
      cdElem.classList.add(styles.now);
      let adbanner = document.getElementById('adbanner');
      cdElem.after(adbanner);
      cdElem.scrollIntoView();
    }
  }, [today])

  useEffect(() => {
    let or = localStorage.getItem('ownRestaurant');
    setOwnRestaurant(or && or.split(';#').filter(o => o.split('|').includes(pageContext.restaurantId)).length > 0);
  }, [pageContext.restaurantId])

  const ToggleOwnRestaurant = () => {
    let or = localStorage.getItem('ownRestaurant');

    let ownRestaurantName = pageContext.menu.RestaurantName;

    if (!or || (or && !or.includes(pageContext.restaurantId))) {
      addOwnRestaurant(pageContext.restaurantId, ownRestaurantName, or);
    } else {
      removeOwnRestaurant(pageContext.restaurantId, or);
    }
  }

  const addOwnRestaurant = (id, name, or) => {
    if (!or) {
      localStorage.setItem('ownRestaurant', `${id}|${name}`);
    } else {
      localStorage.setItem('ownRestaurant', `${or};#${id}|${name}`);
    }

    setOwnRestaurant(true);
  }

  const removeOwnRestaurant = (id, or) => {
    let rArr = or.split(';#').filter(e => !e.split('|').includes(`${id}`));
    localStorage.setItem('ownRestaurant', rArr.join(';#'));

    setOwnRestaurant(false);
  }

  const weekNavigator = (dir, ignore) => {
    if (ignore) return;
    window.location.href = `/menu/${pageContext.restaurantId}/${dir ? '2' : ''}`
  }

  const WeekListTop = <div className={`${styles.weekListTop} ${styles.weekList}`}>
    <span>{isCurrentWeek ? 'Tämä' : 'Ensi'} viikko</span>
    <button className={isCurrentWeek ? styles.disabled : ''} onClick={() => weekNavigator(false, isCurrentWeek)}>&lt;&lt; Tämä viikko</button>
    <button className={!isCurrentWeek ? styles.disabled : ''} onClick={() => weekNavigator(true, !isCurrentWeek)}>Ensi viikko &gt;&gt;</button>
  </div>

  const WeekListBottom = <div className={`${styles.weekListBottom} ${styles.weekList}`}>
    <button className={isCurrentWeek ? styles.disabled : ''} onClick={() => weekNavigator(false, isCurrentWeek)}>&lt;&lt; Tämä viikko</button>
    <span></span>
    <button className={!isCurrentWeek ? styles.disabled : ''} onClick={() => weekNavigator(true, !isCurrentWeek)}>Ensi viikko &gt;&gt;</button>
  </div>

  const IsOwnRestaurant = <button onClick={ToggleOwnRestaurant}><img src={favYes} alt="Poista oma ravintola" /></button>
  const NotOwnRestaurant = <button onClick={ToggleOwnRestaurant}><img src={favNo} alt="Valitse omaksi ravintolaksi" /></button>

  return (
    <Layout>
      <div>
        <style>{`
        /* smaller navi on menu page */
        header a {
            height: 40px!important;
            font-size: 1.8rem!important;
            line-height: 40px!important;
        }
        
        header {
            height: 60px!important;
            padding: 10px!important;
        }
        
        #pageTitle {
            font-size: 2rem!important;
            border-bottom: solid 1px #18447e;
        }
        
        #pageTitle img {
            max-width: 32px;
        }
        
        .main {
            margin-top: 0;
        }
        `}
        </style>
        <SEO
          title={`${pageContext.menu.RestaurantName} ruokalista ${isCurrentWeek ? 'tällä' : 'ensi'} viikolla`}
          description={`${pageContext.menu.RestaurantName} aamupala, lounas, päivällinen tai välipala ${isCurrentWeek ? 'tällä' : 'ensi'} viikolla`}
        />

        <div className={styles.pageTitle}>
          <h1 id={'pageTitle'} >{`${pageContext.menu.RestaurantName}`}
            {ownRestaurant
              ? IsOwnRestaurant
              : NotOwnRestaurant
            }
          </h1>
        </div>

        {WeekListTop}

        {(pageContext.menu.Days && pageContext.menu.Days.length > 0) ? pageContext.menu.Days.map((day, day_i) => (
          <div id={day.Date.replace(/\./ig, '_').split(' ')[1]} key={day.Date} className={`${styles.dayMenu}`}>
            <article>
              <h2>{day.Date}</h2>
              {
                day.Meals.map((meal, meal_i) => {
                  return (
                    <div key={`mt_${day_i}${meal_i}`}>
                      <h3>{meal.MealType}</h3>
                      <div>
                        <span dangerouslySetInnerHTML={{ __html: meal.Name.replace(/\*LBR\*/g, '<br/>') }}></span>
                        {
                          meal.Nutritions ? <button onClick={() => toggle(`nb_${day_i}${meal_i}`)}><img src={infoImg} alt="Ravintotiedot" /></button> : ''
                        }
                      </div>
                      {
                        meal.Nutritions ?
                          <div id={`nb_${day_i}${meal_i}`} className={`${styles.nutritions} ${styles.toggleContent}`}>
                            {
                              meal.Nutritions.map((nutr, nutr_i) => {
                                return (
                                  <div key={`nu_${day_i}${meal_i}${nutr_i}`}><h4>{nutr.Name}</h4>
                                    <h5>Ainesosat:</h5> <span dangerouslySetInnerHTML={{ __html: nutr.Label ? nutr.Label.replace(/\*SS\*/g, '<strong>').replace(/\*ES\*/g, '</strong>') : '' }}></span>
                                    {nutr.Items ?
                                      <><h5>Ravintosisältö (100 g:ssa)</h5>
                                        {nutr.Items.map((item, item_i) => (<span key={`nu_${day_i}${meal_i}${nutr_i}${item_i}`}>{item.Text}<br /></span>))}
                                      </>
                                      : ''}
                                  </div>
                                )
                              })
                            }
                          </div>
                          : ''
                      }
                    </div>
                  )
                })
              }
            </article>
          </div>
        )) : 'Ei ruokalistaa tälle viikolle'}

        {WeekListBottom}

        <div className={styles.abbreviations}>
          VL - Vähälaktoosinen, L - Laktoositon, M - Maidoton, G - Gluteeniton, K - Kananmunaton, S - Sianlihaton, N - Naudanlihaton, MU - Kananmunaton, VEG - Vegaaniruoka, */♥/SY - Sydänmerkki
        </div>

        <div id='adbanner' className={styles.adBannerContainer}>
          <ins className="adsbygoogle"
            data-ad-client="ca-pub-2668838512868610"
            data-ad-slot="2714921034"
            data-ad-format="horizontal,auto"
            data-full-width-responsive="true"></ins>
        </div>
      </div>
    </Layout >
  )
}